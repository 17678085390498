/* eslint-disable */
import { SET_USER_REGISTER, SET_USER_TOKEN_MUTATION } from "@/store/storeconstants";
export default {
  [SET_USER_REGISTER](state, payload) {},
  [SET_USER_TOKEN_MUTATION](state, payload) {
    state.userId = payload.userId;
    state.name = payload.name;
    state.email = payload.email;
    state.level = payload.level;
    state.phone = payload.phone;
    state.token = payload.token;
    state.result = payload.result;
  },
};
