<template>
  <div class="row">
    <table class="table table-condensed" style="width: 60%">
      <tbody>
        <tr>
          <td width="5%">Nama</td>
          <td width="2%">:</td>
          <td style="text-align: left">{{ name }}</td>
        </tr>
        <tr>
          <td width="5%">Hp/Telepon</td>
          <td width="2%">:</td>
          <td style="text-align: left">{{ phone }}</td>
        </tr>
      </tbody>
    </table>
    <div v-if="result">
      <p>Anda sudah pernah melakukan test!</p>
    </div>
    <div v-else>
      <p>Terima Kasih sudah melakukan test papi kostick</p>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "ResultView",
  data() {
    return {
      dataResults: [],
    };
  },
  computed: {
    ...mapState("auth", {
      user_id: (state) => state.userId,
      name: (state) => state.name,
      phone: (state) => state.phone,
      result: (state) => state.result,
    }),
  },
};
</script>

<style lang="scss" scoped>
table td {
  border: none;
}
</style>
