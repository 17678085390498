<template>
  <the-navigation />
  <div class="container mt-4">
    <router-view />
  </div>
</template>

<script>
import TheNavigation from "./components/TheNavigation.vue";
import { AUTH_LOGIN_ACTION } from "./store/storeconstants";
export default {
  name: "App",
  components: { "the-navigation": TheNavigation },
  created() {
    this.$store.dispatch(`auth/${AUTH_LOGIN_ACTION}`);
  },
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;

  a {
    text-decoration: none;
  }
}
</style>
