<template>
  <div class="row mb-4">
    <p>
      PAPI (Personality and Preference Inventory) adalah personality assessment
      atau alat tes penilaian kepribadian terkemuka yang digunakan oleh para
      profesional HR (Human Resource) dan manajer terkait untuk mengevaluasi
      perilaku dan gaya kerja individu pada semua tingkatan. Personality and
      Preference Inventory (PAPI) dibuat oleh Guru Besar Psikologi Industri dari
      Massachusetts, Amerika, yang bernama Dr. Max Martin Kostick pada awal
      tahun 1960-an. Versi Swedia lebih dulu diperkenalkan di awal 1980-an dan
      versi ini diperkenalkan pada tahun 1997 dengan versi ipsatif (PAPI-I) dan
      normatif (PAPI-N). Versi ipsatif, PAPI-I, dirancang untuk digunakan untuk
      pengembangan pribadi, sedangkan versi normatif, PAPI-N, yang dimaksudkan
      untuk digunakan untuk perbandingan dan seleksi. Dasar pemikiran untuk
      desain dan formulasi PAPI didasarkan pada penelitian dan teori kepribadian
      “needs-press” oleh Murray (1938)
    </p>
    <h3 class="mt-4">Personal Info</h3>
    <div class="alert alert-danger" v-if="error">{{ error }}</div>
    <form @submit.prevent="onLogin()">
      <div class="mb-3">
        <label for="email" class="form-label">Email</label>
        <input
          type="email"
          class="form-control"
          id="email"
          v-model="email"
          autocomplete="off"
          placeholder="isi dengan email yang valid"
        />
        <div class="error" v-if="errors.email">{{ errors.email }}</div>
      </div>
      <div class="mb-3">
        <label for="password" class="form-label">Kata Sandi</label>
        <input
          type="password"
          class="form-control"
          id="password"
          v-model="password"
          placeholder="Isi kata sandi yang benar"
        />
        <div class="error" v-if="errors.password">{{ errors.password }}</div>
      </div>
      <span class="d-block mb-2"
        ><strong>Catatan</strong> : Test HANYA bisa dilakukan satu kali,
        pastikan data yang Anda masukkan sudah tepat dan benar</span
      >
      <br />
      <div class="d-grid gap-2 d-sm-flex">
        <button type="submit" class="btn btn-primary">Masuk</button>
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { LOGIN_ACTION } from "@/store/storeconstants";
import LoginValidations from "@/services/LoginValidations";

export default {
  data() {
    return {
      email: "",
      password: "",
      errors: [],
      error: "",
    };
  },
  methods: {
    ...mapActions("auth", {
      login: LOGIN_ACTION,
    }),

    async onLogin() {
      let validations = new LoginValidations(this.email, this.password);
      this.errors = validations.checkValidations();
      if (Object.keys(this.errors).length) {
        return false;
      }

      try {
        await this.login({
          email: this.email,
          password: this.password,
        });

        this.$router.push("/");
      } catch (error) {
        this.error = error;
      }
    },
  },
};
</script>

<style lang="scss">
.error {
  font-size: 0.8rem;
  color: red;
}
</style>
