<template>
  <div
    class="the-chart mt-5 mb-4"
    style="height: 660px"
    width="360px; background: crimson"
  >
    <canvas id="myChart"></canvas>
  </div>
</template>

<script>
import Chart from "chart.js/auto";
export default {
  name: "TheChart",
  props: {
    results: {
      required: true,
      type: Object,
    },
  },
  watch: {
    results(objectResults) {
      let labels = [],
        values = [];

      Object.keys(objectResults).map((key) => {
        labels.push(objectResults[key].label);
        values.push(objectResults[key].value);
      });

      const ctx = document.getElementById("myChart");
      const data = {
        labels: labels,
        datasets: [
          {
            label: "Poin",
            data: values,
            fill: true,
            backgroundColor: "rgba(28, 112, 214, 0.2)",
            borderColor: "rgb(28, 112, 214)",
            pointBackgroundColor: "rgb(28, 112, 214)",
            pointBorderColor: "#fff",
            pointHoverBackgroundColor: "#fff",
            pointHoverBorderColor: "rgb(28, 112, 214)",
          },
        ],
      };
      const config = {
        type: "radar",
        data: data,
        options: {
          elements: {
            line: {
              borderWidth: 2,
            },
          },
          scales: {
            r: {
              angleLines: {
                display: true,
              },
              suggestedMin: 0,
              suggestedMax: 9,
            },
          },
          responsive: true,
          maintainAspectRatio: false,
        },
      };
      new Chart(ctx, config);
    },
  },
};
</script>
