<template>
  <div class="row">
    <div class="col-md-6 offset-md-3">
      <div class="alert alert-danger" v-if="error">{{ error }}</div>
      <form @submit.prevent="onSignup()">
        <div class="mb-3">
          <label for="name" class="form-label">Nama</label>
          <input type="text" class="form-control" id="name" v-model="name" />
          <div class="error" v-if="errors.name">{{ errors.name }}</div>
        </div>
        <div class="mb-3">
          <label for="phone" class="form-label">Telepon</label>
          <input type="text" class="form-control" id="phone" v-model="phone" />
          <div class="error" v-if="errors.phone">{{ errors.phone }}</div>
        </div>
        <div class="mb-3">
          <label for="email" class="form-label">Email</label>
          <input
            type="email"
            class="form-control"
            id="email"
            v-model.trim="email"
          />
          <div class="error" v-if="errors.email">{{ errors.email }}</div>
        </div>
        <div class="mb-3">
          <label for="password" class="form-label">Password</label>
          <input
            type="password"
            class="form-control"
            id="password"
            v-model.trim="password"
          />
          <div class="error" v-if="errors.password">{{ errors.password }}</div>
        </div>
        <div class="d-grid gap-2 d-sm-flex">
          <button type="submit" class="btn btn-primary">Registrasi</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import SignupValidations from "@/services/SignupValidations";
import { mapActions } from "vuex";
import { SIGNUP_ACTION } from "@/store/storeconstants";
export default {
  data() {
    return {
      name: "",
      phone: "",
      email: "",
      password: "",
      errors: [],
      error: "",
    };
  },
  methods: {
    ...mapActions("auth", {
      signup: SIGNUP_ACTION,
    }),

    async onSignup() {
      let validations = new SignupValidations(
        this.name,
        this.phone,
        this.email,
        this.password
      );
      this.errors = validations.checkValidations();
      if (
        "name" in this.errors ||
        "phone" in this.errors ||
        "email" in this.errors ||
        "password" in this.errors
      ) {
        return false;
      }

      try {
        await this.signup({
          name: this.name,
          phone: this.phone,
          email: this.email,
          password: this.password,
        });

        this.$router.push({
          path: "/member",
        });
      } catch (error) {
        this.error = error;
      }
    },
  },
};
</script>
