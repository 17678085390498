import { createRouter, createWebHistory } from "vue-router";
import SignupView from "../views/SignupView.vue";
import LoginView from "../views/LoginView.vue";
import QuestionView from "../views/QuestionView.vue";
import ResultView from "../views/ResultView.vue";
import HomeView from "../views/HomeView.vue";
import MemberView from "../views/MemberView.vue";
import MemberResultView from "../views/MemberResultView.vue";
import store from "@/store";
import { IS_USER_AUTHENTICATE_GETTER } from "@/store/storeconstants";

const routes = [
  {
    path: "/signup",
    name: "signup",
    component: SignupView,
    meta: { auth: true },
  },
  {
    path: "/login",
    name: "login",
    component: LoginView,
    meta: { auth: false },
  },
  {
    path: "/question",
    name: "question",
    component: QuestionView,
    meta: { auth: true },
  },
  {
    path: "/result",
    name: "result",
    component: ResultView,
    meta: { auth: true },
  },
  {
    path: "/member",
    name: "member",
    component: MemberView,
    meta: { auth: true },
  },
  {
    name: "memberResult",
    path: "/member/:id",
    component: MemberResultView,
    meta: { auth: true },
  },
  {
    path: "/",
    name: "home",
    component: HomeView,
    meta: { auth: true },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.meta.auth && !store.getters[`auth/${IS_USER_AUTHENTICATE_GETTER}`]) {
    next("/login");
  } else if (
    !to.meta.auth &&
    store.getters[`auth/${IS_USER_AUTHENTICATE_GETTER}`]
  ) {
    next("/");
  } else {
    next();
  }
});

export default router;
