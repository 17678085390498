<template>
  <div class="row">
    <table class="table table-condensed" style="width: 60%">
      <tbody>
        <tr>
          <td width="5%">Nama</td>
          <td width="2%">:</td>
          <td style="text-align: left">{{ name }}</td>
        </tr>
        <tr>
          <td width="5%">Hp/Telepon</td>
          <td width="2%">:</td>
          <td style="text-align: left">{{ phone }}</td>
        </tr>
      </tbody>
    </table>
    <p>
      Test ini ada 90 nomor isian. Masing-masing nomor memiliki dua pernyataan
      (Pernyataan 1 & 2). Pilihlah salah satu pernyataan yang paling sesuai
      dengan diri Anda dengan men-check pada isian pada kolom yang sudah
      disediakan (Kolom Pilihan). Anda HARUS memilih salah satu yang dominan
      serta mengisi semua nomor.
    </p>

    <h4 class="title">Contoh</h4>
    <table class="table table-primary">
      <tbody>
        <tr>
          <th width="5%">No</th>
          <th style="text-align: center" width="15%">Pilihan</th>
          <th>Pernyataan</th>
        </tr>
      </tbody>
    </table>
    <table class="table table-condensed">
      <tbody>
        <tr>
          <td rowspan="2" width="5%">1</td>
          <td
            style="
              width: 15%;
              text-align: center;
              border-bottom: none;
              padding: 6px 0;
            "
          >
            <div class="form-check">
              <input class="form-check-input" type="radio" checked />
            </div>
          </td>
          <td style="border-bottom: none">Saya biasanya bekerja keras</td>
        </tr>
        <tr>
          <td style="width: 15%; text-align: center; padding: 6px 0">
            <div class="form-check">
              <input class="form-check-input" type="radio" disabled />
            </div>
          </td>
          <td>Saya biasanya bekerja cepat</td>
        </tr>
      </tbody>
    </table>
  </div>

  <div style="text-align: center; margin-top: 15px">
    <button class="btn btn-primary" @click.prevent="onStart()">
      Mulai Test
    </button>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "HomeView",
  computed: {
    ...mapState("auth", {
      user_id: (state) => state.userId,
      name: (state) => state.name,
      phone: (state) => state.phone,
      result: (state) => state.result,
    }),
  },
  mounted() {
    if (this.result) {
      this.onResult();
    }
  },
  methods: {
    onStart() {
      this.$router.replace("/question");
    },
    onResult() {
      this.$router.replace("/result");
    },
  },
};
</script>

<style lang="scss" scoped>
input[type="radio"] {
  font-size: 22px;
}

.form-check .form-check-input {
  float: none;
}
</style>
