/* eslint-disable */
import router from "@/router";
import axiosInstance from "@/services/AxiosInstance";
import LoginValidations from "@/services/LoginValidations";
import SignupValidations from "@/services/SignupValidations";
import {
  AUTH_ACTION,
  LOGIN_ACTION,
  AUTH_LOGIN_ACTION,
  SET_USER_REGISTER,
  SET_USER_TOKEN_MUTATION,
  SIGNUP_ACTION,
  LOGOUT_ACTION,
  SUBMIT_QUESTION_ACTION,
} from "@/store/storeconstants";

export default {
  async [LOGOUT_ACTION](context) {
    localStorage.removeItem("userData");
    context.commit(SET_USER_TOKEN_MUTATION, {
      userId: null,
      name: null,
      email: null,
      token: null,
    });
  },

  async [SIGNUP_ACTION](context, payload) {
    let postData = {
        username: payload.name,
        email: payload.email,
        phone: payload.phone,
        password: payload.password,
      };
  
      try {
        let response = await axiosInstance.post(`/api/register`, postData);
        if (response.status === 200) {
          let { data } = response;
          let { success, message, object } = data;
  
          let tokenData = {
            userId: object.user.id,
            name: object.user.username,
            email: object.user.email,
            phone: object.user.phone,
          };
  
          localStorage.setItem("userData", JSON.stringify(tokenData));
          context.commit(SET_USER_REGISTER, tokenData);
        }
      } catch (error) {
        let { data } = error.response;
        let errorMessage = SignupValidations.getErrorMessageFromCode(
          data.message
        );
        throw errorMessage;
      }
  },

  async [LOGIN_ACTION](context, payload) {
    return context.dispatch(AUTH_ACTION, {
      ...payload,
      url: `/api/login`,
    });
  },

  async [AUTH_LOGIN_ACTION](context, payload) {
    let userData = localStorage.getItem("userData");
    if (userData) {
      context.commit(SET_USER_TOKEN_MUTATION, JSON.parse(userData));
    }
  },

  async [AUTH_ACTION](context, payload) {
    let postData = {
      email: payload.email,
      password: payload.password,
    };

    try {
      let response = await axiosInstance.post(payload.url, postData);
      if (response.status === 200) {
        let { data } = response;
        let { success, message, object } = data;

        let tokenData = {
          userId: object.user.id,
          name: object.user.username,
          email: object.user.email,
          phone: object.user.phone,
          level: object.user.level,
          token: object.api_token,
          result: object.user.results.length > 0 ? true : false,
        };

        if (object.user.status == "0") {
          alert("Akun anda sudah tidak aktif");
          router.replace("/login");
        } else {
          localStorage.setItem("userData", JSON.stringify(tokenData));
          context.commit(SET_USER_TOKEN_MUTATION, tokenData);
        }

      }
    } catch (error) {
      let { data } = error.response;
      let errorMessage = LoginValidations.getErrorMessageFromCode(
        data.message
      );
      throw errorMessage;
    }
  },

  async [SUBMIT_QUESTION_ACTION](context, payload) {
    let postData = {
        "s": payload,
        "user_id": context.state.userId
    }

    try {
        let response = await axiosInstance.post(`/api/app/results/store`, postData);
        if (response.status === 200) {
            // 
        }
    } catch (error) {
        console.log(error);
    }
    
  }
};
