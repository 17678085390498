<template>
  <div class="row mb-4" style="background: #ebf4f7; padding: 0">
    <div
      class="alert alert-warning alert-dismissible fade show"
      role="alert"
      v-if="error"
    >
      <button
        type="button"
        class="btn-close"
        data-bs-dismiss="alert"
        aria-label="Close"
      ></button>
      <h4 class="alert-heading">Gagal!</h4>
      <p>{{ error }}</p>
    </div>
    <table class="table table-primary">
      <tbody>
        <tr>
          <th width="5%" style="text-align: center">No</th>
          <th style="text-align: center" width="15%">Pilihan</th>
          <th>Pernyataan</th>
        </tr>
      </tbody>
    </table>
    <table
      class="table table-condensed"
      style="width: 100%"
      v-for="post in postRender"
      :key="post.id"
    >
      <tbody>
        <tr>
          <td
            rowspan="2"
            width="5%"
            style="vertical-align: top; text-align: center"
          >
            {{ post.id }}
          </td>
          <td
            style="
              width: 15%;
              text-align: center;
              border-bottom: 0;
              padding: 6px 0;
            "
          >
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                :id="`s_${post.id}_0`"
                :name="`s[${post.id}]`"
                :value="`${post.value1}`"
                v-model="postAnswers[post.id - 1]"
              />
            </div>
          </td>
          <td style="border-bottom: none">
            <label class="form-check-label" :for="`s_${post.id}_0`">
              {{ post.statement1 }}
            </label>
          </td>
        </tr>
        <tr>
          <td style="width: 15%; text-align: center; padding: 6px 0">
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                :id="`s_${post.id}_1`"
                :name="`s[${post.id}]`"
                :value="`${post.value2}`"
                v-model="postAnswers[post.id - 1]"
              />
            </div>
          </td>
          <td>
            <label class="form-check-label" :for="`s_${post.id}_1`">
              {{ post.statement2 }}
            </label>
          </td>
        </tr>
      </tbody>
    </table>

    <div class="col mt-3" style="padding-bottom: 15px">
      <div class="button-group">
        <button
          class="btn btn-primary"
          @click.prevent="loadprev()"
          :disabled="prevdisabled"
        >
          Prev
        </button>
        <button
          class="btn btn-primary"
          @click.prevent="loadnext()"
          :disabled="nextdisabled"
        >
          Next
        </button>
      </div>
    </div>
    <div class="col mt-3">
      <button
        class="btn btn-success float-end"
        @click.prevent="onSubmit()"
        :disabled="!nextdisabled"
      >
        Submit
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {
  GET_USER_TOKEN_GETTER,
  SUBMIT_QUESTION_ACTION,
} from "@/store/storeconstants";
import axiosInstance from "@/services/AxiosInstance";
export default {
  name: "QuestionView",
  data() {
    return {
      start: 0,
      end: 5,
      posts: [],
      postRender: [],
      postAnswers: [],
      error: "",
      prevdisabled: false,
      nextdisabled: false,
    };
  },
  computed: {
    ...mapGetters("auth", {
      token: GET_USER_TOKEN_GETTER,
    }),
  },
  mounted() {
    axiosInstance.get(`/api/app/statements`).then((response) => {
      this.formatDataPosts(response.data);
    });

    if (this.start == 0) {
      this.prevdisabled = true;
    }
  },
  methods: {
    formatDataPosts(posts) {
      for (let key in posts) {
        this.posts.push({ ...posts[key], id: parseInt(key) + 1 });
        this.postAnswers.push(null);
      }

      for (let i = this.start; i < this.end; i++) {
        this.postRender.push({ ...this.posts[i] });
      }
    },

    loadnext() {
      this.start += this.postRender.length;
      this.end += this.postRender.length;

      this.postRender = [];
      for (let i = this.start; i < this.end; i++) {
        this.postRender.push({ ...this.posts[i] });
      }

      if (this.end == 90) {
        this.nextdisabled = true;
      }

      this.prevdisabled = false;
    },

    loadprev() {
      this.start -= this.postRender.length;
      this.end -= this.postRender.length;

      this.postRender = [];
      for (let i = this.start; i < this.end; i++) {
        this.postRender.push({ ...this.posts[i] });
      }

      if (this.start == 0) {
        this.prevdisabled = true;
      }

      this.nextdisabled = false;
    },

    ...mapActions("auth", {
      submit: SUBMIT_QUESTION_ACTION,
    }),

    async onSubmit() {
      let datanull = [];
      this.postAnswers.map((item, index) => {
        if (item == null) {
          datanull.push(index + 1);
        }
      });

      if (datanull.length > 0) {
        this.error = `Pertanyaan No: ${datanull.join(", ")} Belum diisi!`;
        return false;
      }

      try {
        await this.submit(this.postAnswers);
      } catch (error) {
        this.error = error;
      }

      this.$router.push("/result");
    },
  },
};
</script>

<style lang="scss" scoped>
table {
  margin-bottom: 0;
  td {
    padding: 10px 0;
  }
}

input[type="radio"] {
  font-size: 22px;
}

.form-check .form-check-input {
  float: none;
}
</style>
