<template>
  <div class="row">
    <div class="d-grid gap-2 d-sm-flex p-0 mb-3">
      <button class="btn btn-primary" @click.prevent="goSignup()">
        Add Member
      </button>
    </div>
    <table
      class="table table-primary table-striped table-condensed table-hover"
    >
      <thead>
        <tr>
          <th>User Id</th>
          <th>Nama</th>
          <th>Email</th>
          <th>Telepon</th>
          <th>Status</th>
          <th>Hasil</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="member in dataMembers" :key="member.id">
          <td>{{ member.id }}</td>
          <td>{{ member.username }}</td>
          <td>{{ member.email }}</td>
          <td>{{ member.phone }}</td>
          <td>
            <div class="form-check form-switch">
              <input
                class="form-check-input"
                type="checkbox"
                role="switch"
                id="flexSwitchCheckChecked"
                :value="member.status"
                :checked="member.status == '1' ? 1 : 0"
                @change="onChangeStatus(member.status, member.id)"
              />
            </div>
          </td>
          <td>
            <a
              href="#"
              class=""
              v-if="member.results.length > 0"
              @click.prevent="goMember(member.id)"
              ><i class="bi bi-briefcase-fill"></i> Hasil</a
            ><span v-else>Kosong</span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import axiosInstance from "@/services/AxiosInstance";
import { mapState } from "vuex";
export default {
  name: "ResultView",
  data() {
    return {
      dataMembers: [],
    };
  },
  computed: {
    ...mapState("auth", {
      user_id: (state) => state.userId,
      name: (state) => state.name,
      phone: (state) => state.phone,
      level: (state) => state.level,
      result: (state) => state.result,
    }),
  },
  mounted() {
    if (this.level == "member") {
      this.$router.replace("/");
    }
    localStorage.removeItem("user_result");
    this.formatData();
  },
  methods: {
    formatData() {
      axiosInstance.get(`/api/app/members`).then((response) => {
        this.dataMembers = response.data.data;
      });
    },

    goSignup() {
      this.$router.push("/signup");
    },

    async onChangeStatus(stat, id) {
      let status = stat == 1 ? 0 : 1;
      let payload = {
        status: status,
      };

      await axiosInstance.put(`/api/app/users/${id}/status`, payload);
      this.formatData();
    },

    goMember(id) {
      let member = this.dataMembers.filter((el) => el.id == id)[0];
      localStorage.setItem("user_result", JSON.stringify(member));

      this.$router.push({
        name: "memberResult",
        params: { id: id },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
table tr td,
table tr th {
  text-align: center;
}
.form-check .form-check-input {
  float: none;
}
</style>
