/* eslint-disable prettier/prettier */
export const SIGNUP_ACTION = "[actions] signup user";
export const LOGIN_ACTION = "[actions] login user";
export const LOGOUT_ACTION = "[actions] logout user";
export const AUTH_ACTION = "[actions] DO User Auth";
export const AUTH_LOGIN_ACTION = "[actions] Auth Auto Login";
export const SET_USER_REGISTER = "[mutations] set user register";
export const SET_USER_TOKEN_MUTATION = "[mutations] set user token";
export const GET_USER_TOKEN_GETTER = "[getters] auth user token";
export const IS_USER_AUTHENTICATE_GETTER = "[getters] is user authenticated";
export const SUBMIT_QUESTION_ACTION = "[actions] submit question";