<template>
  <div class="row">
    <h3 style="padding: 0">Interpretasi Hasil Test PAPI Kostick</h3>
    <table class="table table-condensed" style="width: 60%">
      <tbody>
        <tr>
          <td width="5%">Nama</td>
          <td width="2%">:</td>
          <td style="text-align: left">{{ member.username }}</td>
        </tr>
        <tr>
          <td width="5%">Hp/Telepon</td>
          <td width="2%">:</td>
          <td style="text-align: left">{{ member.phone }}</td>
        </tr>
      </tbody>
    </table>
    <ul class="list-group list-group-item-primary">
      <li
        class="list-group-item"
        v-for="result in dataResults"
        :key="result.id"
      >
        <strong>{{ result.aspect }}</strong>
        <ul class="list-group">
          <li
            class="list-group-item list-group-item-info"
            style="border: none"
            v-for="role in result.roles"
            :key="role.id"
          >
            <strong>{{ role.role }}</strong>
            <ol class="list-group list-group-numbered">
              <li
                class="list-group-item d-flex justify-content-between align-items-start list-group-item-info"
                style="border: none"
                v-for="description in role.descriptions"
                :key="description.id"
              >
                <div class="ms-2 me-auto">
                  {{ description.description }}
                </div>
              </li>
            </ol>
            <div v-if="role.results">
              <div v-for="rule in role.rules" :key="rule.id">
                <p
                  class="mb-1"
                  v-if="
                    parseInt(role.results.value) >= parseInt(rule.low_value) &&
                    parseInt(role.results.value) <= parseInt(rule.high_value)
                  "
                >
                  <span style="background: aqua"
                    >Poin: {{ role.results.value }} ({{
                      rule.interpretation
                    }})</span
                  >
                </p>
              </div>
            </div>
          </li>
        </ul>
      </li>
    </ul>
    <the-chart :results="dataCharts" />
  </div>
</template>

<script>
import TheChart from "@/components/TheChart.vue";
import axiosInstance from "@/services/AxiosInstance";
import { mapState } from "vuex";
export default {
  name: "ResultView",
  data() {
    return {
      dataResults: [],
      dataCharts: [],
      id: 0,
      member: {},
    };
  },
  components: {
    "the-chart": TheChart,
  },
  computed: {
    ...mapState("auth", {
      user_id: (state) => state.userId,
      name: (state) => state.name,
      phone: (state) => state.phone,
      level: (state) => state.level,
      result: (state) => state.result,
    }),
  },
  mounted() {
    if (this.level == "member") {
      this.$router.replace("/");
    }
    this.id = this.$route.params.id;
    this.member = JSON.parse(localStorage.getItem("user_result"));
    axiosInstance
      .get(`/api/app/aspects/result/user/${this.id}`)
      .then((response) => {
        this.formatDataGet(response.data);
      });
  },
  methods: {
    formatDataGet(data) {
      this.dataResults = data.data;

      let roles = [];
      let rolestmp = [];
      this.dataResults.map((el) => {
        rolestmp.push(el.roles);
      });

      for (let index = 0; index < rolestmp.length; index++) {
        roles = [...roles, ...rolestmp[index]];
      }

      this.dataCharts = Object.fromEntries(
        roles.map((el) => [
          el.id,
          {
            label: el.role,
            value: el.results ? el.results.value : 0,
          },
        ])
      );
    },
  },
};
</script>

<style lang="scss" scoped>
table td {
  border: none;
}
</style>
