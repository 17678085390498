/* eslint-disable prettier/prettier */

import mutations from "./mutations";
import getters from "./getters";
import actions from "./actions";

export default {
  namespaced: true,
  state() {
    return {
      userId: "",
      name: "",
      email: "",
      level: "",
      token: "",
      result: "",
    };
  },
  mutations,
  getters,
  actions,
};
